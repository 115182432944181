@font-face {
	font-family: 'Inter';
	src: local('Inter'), url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 100;
	src: local('Inter'), url(../fonts/Inter/Inter-Thin.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 200;
	src: local('Inter'), url(../fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 300;
	src: local('Inter'), url(../fonts/Inter/Inter-Light.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 400;
	src: local('Inter'), url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 500;
	src: local('Inter'), url(../fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 600;
	src: local('Inter'), url(../fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 700;
	src: local('Inter'), url(../fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 800;
	src: local('Inter'), url(../fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 900;
	src: local('Inter'), url(../fonts/Inter/Inter-Black.ttf) format('truetype');
}

main,
html,
body,
input,
label,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: 'Inter', 'Ubuntu', 'sans-serif', 'Segoe UI', 'Oxygen', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-track {
	background: #aaa;
}

::-webkit-scrollbar-thumb {
	background: #555;
}

::-webkit-scrollbar-thumb:hover {
	background: #333;
	cursor: pointer;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-row,
.MuiButtonBase-root {
	font-family: 'Inter' !important;
}

.MuiDataGrid-columnHeadersInner {
	background: #dff2f1 !important;
}
